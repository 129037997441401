// src/components/OpenNotebookButton.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, Button, CircularProgress, Alert, List, ListItem, ListItemText, Paper, Container } from '@mui/material';
import CandidateAnswers from './CandidateTestAnswers';

const OpenNotebookButton = ({ candidateId }) => {
    const [candidateTests, setCandidateTests] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [practicalExercises, setPracticalExercises] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [notebookUrl, setNotebookUrl] = useState('');
    const [iframeLoading, setIframeLoading] = useState(true);
    const [iframeKey, setIframeKey] = useState(0);
    const [answerSheetOpen, setAnswerSheetOpen] = useState(false);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const userToken = localStorage.getItem('authKey');
    const apiurl = process.env.REACT_APP_API_URL;

    const handleOpenNotebook = async (email) => {
        try {
            const response = await axios.post(
                `${apiurl}/check-email-exists/${candidateId}/`,
                { email }
            );
            setCandidateTests(response.data.candidate_tests);
            setError('');
        } catch (error) {
            console.error('Failed to fetch candidate tests:', error);
            setError('No practical test conducted for the Candidate');
        } finally {
            setIsLoading(false);
        }
    };

    const openNotebook = async (practicalExerciseId, test, email) => {
        try {
            const response = await fetch(`${apiurl}/api/open-notebook/${practicalExerciseId}/?test=${test}&email=${email}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${userToken}`,
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setNotebookUrl(data.url + '/notebooks/Questions.ipynb');
            localStorage.setItem('container_id',data.container_id);
            setOpenModal(true);
        } catch (error) {
            console.error('Failed to open notebook:', error);
        }
    };
    

    const stopJupyterInstance = async () => {
        try {
            const containerId = localStorage.getItem('container_id'); // Retrieve container ID from localStorage
            await axios.post(`${apiurl}/api/stop-container/`, {
                container_id: containerId
            }, {
                headers: {
                    'Authorization': `Token ${userToken}`,
                    'Content-Type': 'application/json'
                }
            });
            localStorage.removeItem('container_id'); // Optionally remove the container ID from localStorage after stopping the container
        } catch (error) {
            console.error('Failed to stop Docker container:', error);
        }
    };
    
    

    useEffect(() => {
        if (openModal && notebookUrl) {
            setIframeLoading(true);
            const timer = setTimeout(() => {
                setIframeLoading(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [openModal, notebookUrl]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiurl}/api/candidatesprofile/${candidateId}`, {
                    headers: {
                        'Authorization': `Token ${userToken}`,
                        'Content-Type': 'application/json'
                    },
                });
                const { email } = response.data;
                handleOpenNotebook(email);
                fetchUserJupyterInstance();
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setError('Failed to fetch data. Please try again later.');
                setIsLoading(false);
            }
        };

        fetchData();
    }, [candidateId, userToken]);

    const fetchUserJupyterInstance = async () => {
        try {
            const response = await axios.get(`${apiurl}/api/user_jupyter_instance/${candidateId}/`, {
                headers: {
                    'Authorization': `Token ${userToken}`,
                    'Content-Type': 'application/json'
                },
            });
            const { user_jupyter_instances, email } = response.data; // Assuming email is part of the response or it can be fetched from another state
            setPracticalExercises(user_jupyter_instances.map(instance => ({
                name: instance.practical_exercise_name,
                id: instance.practical_exercise,
                test: instance.user,  // Adding test to each exercise
                email: email          // Adding email to each exercise
            })));
        } catch (error) {
            console.error('Failed to fetch user jupyter instance:', error);
        }
    };
    

    const handleAnswerSheetClick = () => {
        setAnswerSheetOpen(true);
    };

    const handleCloseAnswerSheet = () => {
        setAnswerSheetOpen(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <Modal
                open={openModal}
                onClose={() => {
                    stopJupyterInstance(selectedExercise.test);
                    setOpenModal(false);
                }}
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 1500, height: 800, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Notebook
                    </Typography>
                    <Box sx={{ height: 'calc(100% - 48px)' }}>
                        {iframeLoading ? (
                            <div>Loading...</div>
                        ) : (
                            <iframe key={iframeKey} src={notebookUrl} title="Notebook" width="100%" height="100%" ></iframe>
                        )}
                    </Box>
                    <Button variant="contained" color="primary" onClick={() => {
    stopJupyterInstance();
    setOpenModal(false);
}} style={{ position: 'absolute', bottom: 10, right: 10 }}>
    Close
</Button>

                </Box>
            </Modal>

            <hr />
            {practicalExercises.length > 0 ? (
                <div>
                    <h4>Practical Tests:</h4>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Practical Test Name</th>
                                <th>Open</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                        {practicalExercises.map((exercise) => (
    <tr key={exercise.id}>
        <td>{exercise.name}</td>
        <td>
            <Button variant="contained" color="primary" onClick={() => openNotebook(exercise.id, exercise.test, exercise.email)}>
                Open Notebook
            </Button>
        </td>
    </tr>
))}
                      </tbody>
                  </table>
              </div>
          ) : (
              <p>No practical tests available.</p>
          )}
           {candidateTests.length > 0 ? (
        <div>
          <h4>Tests:</h4>
          <table className="table">
            <thead>
              <tr>
                <th>Test</th>
                <th>Score</th>
                <th>Answer Sheet</th>
              </tr>
            </thead>
            <tbody>
           {candidateTests.map((test, index) => (
                <tr key={index}>
                  <td>{test.test_name}</td>
                  <td>{test.score}</td>
<td>
          <Button variant="contained" color="secondary" onClick={handleAnswerSheetClick}>
              View Answer Sheet
          </Button>
          </td>
          </tr>
           ))}
          <CandidateAnswers 
              candidateId={candidateId} 
              open={answerSheetOpen} 
              onClose={handleCloseAnswerSheet} 
          />
          </tbody>
          </table>
          </div>
           ):(
            <p>No tests found</p>  
           )}
      </div>
  );
};

export default OpenNotebookButton;
